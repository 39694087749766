export const ellipsizeMiddle = (str: string) => {
  if (str.length > 22) {
    const res =
      str.substr(0, Math.min(str.lastIndexOf('.'), 20) - 2) +
      '..' +
      str.substr(str.lastIndexOf('.'), str.length)
    return res
  }
  return str
}

export const dateFormat = (date: Date) => {
  const d = new Date(date)
  return d
    .toLocaleString('ro-RO')
    .substr(0, d.toLocaleString('ro-RO').lastIndexOf(','))
}

export const dateRangeFormat = (startDateStr: Date, endDateStr: Date) => {
  const startDate = new Date(startDateStr)
  const endDate = endDateStr ? new Date(endDateStr) : null

  if (
    !endDate ||
    (startDate.getDate() == endDate.getDate() &&
      startDate.getMonth() == endDate.getMonth() &&
      startDate.getFullYear() == endDate.getFullYear())
  ) {
    return longDateFormat(startDateStr)
  }

  if (
    startDate.getMonth() == endDate.getMonth() &&
    startDate.getFullYear() == endDate.getFullYear()
  ) {
    return `${startDate.getDate()} – ${endDate.getDate()} ${startDate.toLocaleString(
      'ro-RO',
      {
        month: 'long',
        year: 'numeric',
      }
    )}`
  }

  if (startDate.getFullYear() == endDate.getFullYear()) {
    return `${startDate.toLocaleString('ro-RO', {
      month: 'long',
      day: 'numeric',
    })} – ${endDate.toLocaleString('ro-RO', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })}`
  }

  return `${startDate.toLocaleString('ro-RO', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })} – ${endDate.toLocaleString('ro-RO', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })}`

  // let res = d.toLocaleString('ro-RO', {
  //   month: 'long',
  //   day: 'numeric',
  //   year: 'numeric',
  // })

  return ''
}

export const longDateFormat = (date: Date) => {
  const d = new Date(date)

  let res = d.toLocaleString('ro-RO', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })

  if (d.getHours() > 0 || d.getMinutes() > 0) {
    res +=
      ', ' + d.toLocaleString('ro-RO', { hour: 'numeric', minute: 'numeric' })
  }

  return res
}
