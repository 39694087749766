import {
  Breadcrumbs,
  Footer,
  H1,
  Header,
  Main,
  Page,
  Wrap,
} from '@/design-system'
import { Evenimente, MenuItems, Pages } from '@/generated/graphql'
import { sdk } from '@/lib/sdk'
import { dateRangeFormat } from '@/lib/utils'
import Image from 'next/image'
import Link from 'next/link'
import { SubcategoryGrid } from '../../components/SubcategoryCard'

const TicketIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
    />
  </svg>
)

const PencilSquareIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
    />
  </svg>
)

const ArrowTopRightOnSquareIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
    />
  </svg>
)

export async function getStaticProps() {
  const { menu_items, pages } = await sdk().menuByUrl({
    url: '/primaria/conducere',
  })

  const evenimente = (await await sdk().evenimente_tineri()).evenimente

  return {
    props: { menu: menu_items, page: pages[0], evenimente },
  }
}

export default function EvenimentePage({
  menu,
  page,
  evenimente,
}: {
  menu: MenuItems[]
  page: Pages
  evenimente: Evenimente[]
}) {
  return (
    <Page>
      <Header menu={menu} />
      <Wrap>
        <Breadcrumbs> </Breadcrumbs>
        <H1>Calendar de evenimente pentru tineri</H1>
      </Wrap>

      <Wrap>
        <section className="bg-white space-y-4 my-8">
          <p>Te invităm la o serie de evenimente pentru TINEri!</p>
          <p>
            Haide să sărbătorim împreună Ziua Națională a Tineretului în
            perioada 21 aprilie - 1 mai 2024!
          </p>
          <p>
            Filme, piese de teatru, concerte, competiție cu bărci Dragon,
            picnicuri, proiecții de film și alte evenimente organizate de
            parteneri împreună cu membrii Consiliului Consultativ pentru Tineri
            al Municipiului Timișoara, structură din care fac parte peste 20 de
            organizații de și pentru tineret. Vezi mai jos toate evenimentele
            pregătite!
          </p>
        </section>
      </Wrap>

      <Main className="bg-slate-200">
        <SubcategoryGrid>
          {evenimente.map((eveniment) => (
            <li className="sm:max-w-xs bg-white rounded-lg list-none flex flex-col">
              <div className="relative w-full h-44">
                <Image
                  src={`https://${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_DOMAIN}/${eveniment.poza?.id}`}
                  alt={eveniment.poza?.title}
                  fill={true}
                  className="rounded-t-lg"
                  style={{ objectFit: 'cover' }}
                />
              </div>
              <div className="rounded-lg block no-underline space-y-2 p-4 min-h-[120px] flex-1">
                {eveniment.categorii && (
                  <div className="flex gap-x-2 overflow-y-auto">
                    {eveniment.categorii.map((categorie) => (
                      <div className="text-slate-500 border-slate-500 text-sm border inline-block rounded-md px-2 py-1">
                        {categorie[0].toUpperCase() + categorie.substr(1)}
                      </div>
                    ))}
                  </div>
                )}
                <div className="text-slate-500 text-sm">
                  {dateRangeFormat(
                    eveniment.data_inceput,
                    eveniment.data_sfarsit
                  )}
                </div>
                <div className="text-lg tracking-tight font-bold leading-6">
                  {eveniment.titlu}
                </div>
              </div>
              <div className="p-4">
                <div className="flex gap-x-3">
                  {eveniment.link_bilete && (
                    <a
                      className="bg-blue-100 no-underline rounded-md w-full py-2 flex items-center justify-center gap-x-2 text-primary font-semibold hover:bg-primary hover:text-white"
                      href={eveniment.link_bilete}
                    >
                      <TicketIcon className="w-5 h-5 -mt-0.5" /> Bilete
                    </a>
                  )}
                  {eveniment.link_inscriere && (
                    <a
                      className="bg-blue-100 no-underline rounded-md w-full py-2 flex items-center justify-center gap-x-2 text-primary font-semibold hover:bg-primary hover:text-white"
                      href={eveniment.link_inscriere}
                    >
                      <PencilSquareIcon className="w-5 h-5 -mt-0.5" /> Înscriere
                    </a>
                  )}
                  {eveniment.link_detalii && (
                    <a
                      className="bg-blue-100 no-underline rounded-md w-full py-2 flex items-center justify-center gap-x-2 text-primary font-semibold hover:bg-primary hover:text-white"
                      href={eveniment.link_detalii}
                    >
                      <ArrowTopRightOnSquareIcon className="w-5 h-5 -mt-0.5" />{' '}
                      Detalii
                    </a>
                  )}
                </div>
              </div>
            </li>
          ))}
        </SubcategoryGrid>
      </Main>

      <Footer />
    </Page>
  )
}
